<template>
  <section class="invoice-preview-wrapper">
    <!-- add comm -->
    <div id="lottie" v-show="show">
      <div class="loading-logo">
        <img  style="margin-left: -4rem;"  alt="Logo" src="/logo-transparent.png" />
      </div>
      <div class="loading">
        <div class="effect-1 effects" />
        <div class="effect-2 effects" />
        <div class="effect-3 effects" />
      </div>
    </div>
    <template>
      <b-row v-if="changeOrder.id" class="pb-1 notprintable">
        <b-col cols="7" style="font-weight: 600; font-size: 11.9px; line-height: 12px">
          <h2
            v-if="changeOrder.id"
            class="notprintable"
            style="font-weight: 700 !important; font-size: 28px!important; line-height: 34px;!important"
          >
            {{
              changeOrder.opportunity.address + ", " + changeOrder.opportunity.geography
            }}
          </h2>
          <uil-suitcase class="logo" size="15px" />
          {{ changeOrder.opportunity.account_name }}
          <span style="padding-left: 20px">
            <uil-tape class="logo" size="15px" />
            {{ changeOrder.opportunity.rentable_sqft + " sqft" }}
          </span>
          <span style="padding-left: 20px">
            <uil-bill class="logo" size="15px" />
            Change Order {{ changeOrder.internal_id }}
          </span>
          <p class="pt-1">
            <b-badge pill :class="badgeClass(changeOrder.status)">
              {{ changeOrder.status }}
            </b-badge>
          </p>
        </b-col>
        <b-col cols="5" style="text-align: end; align-self: self-end">
          <b-button
            class="mr-1"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            @click="$bvModal.show('approveChangeOrder')"
            v-show="changeOrder.status == 'Sent to Client'"
            :disabled="changeOrder.status == 'Archived'"
          >
            <uil-check class="logo" size="15" /> &nbsp; Mark as Client Approved
          </b-button>
          <b-button
            class="mr-1"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            v-show="changeOrder.status == 'Quote In Progress'"
            @click="$bvModal.show('markAsSent')"
          >
            <uil-message class="logo" size="15" /> &nbsp; Mark as Sent
          </b-button>
          <b-button
            variant="outline-secondary"
            class="btn-icon mr-1"
            style="width: 38px; height: 38px"
            :href="`/opportunities/${this.$route.params.opportunityId}/change-order/${changeOrder.id}/edit`"
          >
            <uil-edit-alt class="logo" size="15" />
          </b-button>
          <b-dropdown
            no-caret
            toggle-class="rounded-square p-0 "
            style="width: 38px; height: 38px"
            variant="outline-secondary"
            offset="-175"
          >
            <template #button-content>
              <feather-icon class="btn-icon" icon="MoreVerticalIcon" size="15" />
            </template>
            <b-dropdown-item @click="printInvoice">
              <uil-import class="logo" size="15" /> Save as PDF
            </b-dropdown-item>
            <b-dropdown-item
              @click="$bvModal.show('archiveQuote')"
              :disabled="
                changeOrder.status == 'Approved' || changeOrder.status == 'Archived'
              "
            >
              <uil-archive class="logo" size="15" /> Archive Change Order
            </b-dropdown-item>
            <b-dropdown-divider
              v-if="
                isAdmin == 1 &&
                (changeOrder.status == 'Archived' || changeOrder.status == 'Approved')
              "
            ></b-dropdown-divider>
            <b-dropdown-group
              v-if="
                isAdmin == 1 &&
                (changeOrder.status == 'Archived' ||
                  changeOrder.status == 'Approved' ||
                  changeOrder.status == 'Sent to Client')
              "
              id="dropdown-group-2"
            >
              <b-dropdown-header>
                <div style="color: #7e7e7e">Digital (Admin Only)</div>
              </b-dropdown-header>
              <b-dropdown-item
                @click="revertToQuoteStatus"
                v-if="changeOrder.status == 'Archived'"
              >
                <uil-file-minus-alt class="logo" size="15" /> Unarchive CO
              </b-dropdown-item>
              <b-dropdown-item
                @click="revertToQuoteStatus"
                v-if="
                  changeOrder.status == 'Approved' ||
                  changeOrder.status == 'Sent to Client'
                "
              >
                <uil-arrow-circle-left class="logo" size="15" /> Revert to Quote in
                Progress
              </b-dropdown-item>
            </b-dropdown-group>
          </b-dropdown>
        </b-col>
      </b-row>
      <b-row v-if="changeOrder.id" class="invoice-preview printable">
        <!-- Col: Left (Invoice Container) -->
        <b-col md="8" xl="9">
          <contract-view-card :changeorder="changeOrder" />
          <b-card class="invoice-preview-card nextPage" no-body>
            <div
              style="background-color: rgba(50, 61, 76, 1)"
              class="invoice_header_second text-white invoice-padding m-0 p-0"
            >
              <!-- Header -->
              <b-card-body class="invoice-padding pb-0">
                <div
                  class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
                >
                  <!-- Header: Left Content -->
                  <div style="display: flex; align-items: center">
                    <div class="logo-wrapper">
                      <logo />
                    </div>
                  </div>

                  <!-- Header: Right Content -->
                  <div class="mt-2 text-right" v-html="$t('changeOrder.address', 'en')" />
                </div>
              </b-card-body>
            </div>
            <!-- Invoice Client & Payment Details -->
            <b-card-body class="invoice-padding pt-0">
              <b-row class="invoice-spacing">
                <b-col cols="12" class="pl-0 pb-2">
                  <h2
                    style="
                      font-style: normal !important;
                      font-weight: 500 !important;
                      font-size: 28px !important;
                      color: #636363 !important;
                    "
                    class="mt-2"
                  >
                    CHANGE ORDER
                    {{ changeOrder.change_order_number }}
                  </h2>
                </b-col>
              </b-row>
              <hr class="w-100">
              <b-row class="invoice-spacing my-3">
                <b-col class="pl-0" cols="6">
                  <h6 class="header-label">
                    {{ $t("changeOrder.addressLabel", "en") }}
                  </h6>
                  <h2
                    class="card-text mb-25"
                    style="
                      font-weight: 500 !important;
                      font-size: 28px !important;
                      line-height: 26px !important;
                      color: #636363 !important;
                      text-transform: uppercase;
                    "
                  >
                    {{ changeOrder.opportunity.address }}
                  </h2>
                  <h2
                    class="card-text mb-25"
                    style="
                      font-weight: 500 !important;
                      font-size: 28px !important;
                      line-height: 26px !important;
                      color: #636363 !important;
                      text-transform: uppercase;
                    "
                  >
                    {{ changeOrder.opportunity.geography }}
                  </h2>
                </b-col>
              </b-row>
              <!--               <b-row v-show="constructionLines.length > 0">
                <b-col cols="12">
                  <b-table-simple fixed table-class="print-friendly">
                    <b-thead>
                      <b-tr>
                        <b-th class="table-background-color" colspan="6">
                          {{ $t("changeOrder.items", "en") }}
                        </b-th>
                        <b-th class="table-background-color" colspan="2">
                          {{ $t("changeOrder.quantity", "en") }}
                        </b-th>
                        <b-th class="table-background-color" colspan="2">
                          {{ $t("changeOrder.unitPrice", "en") }}
                        </b-th>
                        <b-th class="table-background-color" colspan="2">
                          {{ $t("changeOrder.subtotal", "en") }}
                        </b-th>
                      </b-tr>
                    </b-thead>
                    <b-tbody>
                      <b-tr
                        class="border-group-details"
                        v-for="(constructionLine, index) in constructionLines"
                        :key="index"
                      >
                        <b-td colspan="6">
                          {{ constructionLine.name }} <br />
                          {{ constructionLine.client_description }}
                        </b-td>
                        <b-td colspan="2">
                          {{ constructionLine.quantity }}
                        </b-td>
                        <b-td colspan="2">
                          {{ numberFormatter(constructionLine.unit_price) }}
                        </b-td>
                        <b-td colspan="2">
                          {{ numberFormatter(constructionLine.total_price) }}
                        </b-td>
                      </b-tr>
                    </b-tbody>
                  </b-table-simple>
                </b-col>
              </b-row>
              <b-row v-show="ffeLines.length > 0">
                <b-col cols="12">
                  <b-table-simple fixed table-class="print-friendly">
                    <b-thead>
                      <b-tr>
                        <b-th class="table-background-color" colspan="6">
                          {{ $t("changeOrder.items", "en") }}
                        </b-th>
                        <b-th class="table-background-color" colspan="2">
                          {{ $t("changeOrder.quantity", "en") }}
                        </b-th>
                        <b-th class="table-background-color" colspan="2">
                          {{ $t("changeOrder.unitPrice", "en") }}
                        </b-th>
                        <b-th class="table-background-color" colspan="2">
                          {{ $t("changeOrder.subtotal", "en") }}
                        </b-th>
                      </b-tr>
                    </b-thead>
                    <b-tbody>
                      <b-tr
                        class="border-group-details"
                        v-for="(ffeLine, index) in ffeLines"
                        :key="index"
                      >
                        <b-td colspan="6">
                          {{ ffeLine.name }}<br />
                          {{ ffeLine.client_description }}
                        </b-td>
                        <b-td colspan="2">
                          {{ ffeLine.quantity }}
                        </b-td>
                        <b-td colspan="2">
                          {{ numberFormatter(ffeLine.unit_price) }}
                        </b-td>
                        <b-td colspan="2">
                          {{ numberFormatter(ffeLine.total_price) }}
                        </b-td>
                      </b-tr>
                    </b-tbody>
                  </b-table-simple>
                </b-col>
              </b-row>
              <b-row v-show="avitLines.length > 0">
                <b-col cols="12">
                  <b-table-simple fixed table-class="print-friendly">
                    <b-thead>
                      <b-tr>
                        <b-th class="table-background-color" colspan="6">
                          {{ $t("changeOrder.items", "en") }}
                        </b-th>
                        <b-th class="table-background-color" colspan="2">
                          {{ $t("changeOrder.quantity", "en") }}
                        </b-th>
                        <b-th class="table-background-color" colspan="2">
                          {{ $t("changeOrder.unitPrice", "en") }}
                        </b-th>
                        <b-th class="table-background-color" colspan="2">
                          {{ $t("changeOrder.subtotal", "en") }}
                        </b-th>
                      </b-tr>
                    </b-thead>
                    <b-tbody>
                      <b-tr
                        class="border-group-details"
                        v-for="(avitLine, index) in avitLines"
                        :key="index"
                      >
                        <b-td colspan="6">
                          {{ avitLine.name }} <br />
                          {{ avitLine.client_description }}
                        </b-td>
                        <b-td colspan="2">
                          {{ avitLine.quantity }}
                        </b-td>
                        <b-td colspan="2">
                          {{ numberFormatter(avitLine.unit_price) }}
                        </b-td>
                        <b-td colspan="2">
                          {{ numberFormatter(avitLine.total_price) }}
                        </b-td>
                      </b-tr>
                    </b-tbody>
                  </b-table-simple>
                </b-col>
              </b-row> -->
              <b-row>
                <b-col cols="12">
                  <b-table-simple
                    fixed
                    table-class="print-friendly"
                    v-show="changeOrderItems.length > 0"
                  >
                    <b-thead>
                      <b-tr>
                        <b-th class="table-background-color" colspan="6">
                          {{ $t("changeOrder.items", "en") }}
                        </b-th>
                        <b-th class="table-background-color text-right" colspan="2">
                          {{ $t("changeOrder.quantity", "en") }}
                        </b-th>
                        <b-th class="table-background-color text-right" colspan="2">
                          {{ $t("changeOrder.unitPrice", "en") }}
                        </b-th>
                        <b-th class="table-background-color text-right" colspan="2">
                          {{ $t("changeOrder.subtotal", "en") }}
                        </b-th>
                      </b-tr>
                    </b-thead>
                    <b-tbody style="border-bottom: 1px solid #d8d6de !important">
                      <b-tr
                        class="border-group-details"
                        v-for="(item, index) in changeOrderItems"
                        :key="index"
                      >
                        <b-td colspan="6">
                          <div
                            style="
                              font-style: normal;
                              font-weight: 600;
                              font-size: 14px;
                              line-height: 21px;
                              display: flex;
                              align-items: center;
                              color: #6e6b7b;
                            "
                          >
                            {{ item.name }}
                          </div>
                          <div
                            v-if="item.client_description"
                            class="op-sc-details child-description"
                            style="
                              font-style: normal;
                              font-weight: 400;
                              font-size: 14px;
                              line-height: 21px;
                              display: flex;
                              align-items: center;
                              color: #6e6b7b;
                              white-space: pre-line;
                            "
                          >
                            {{ item.client_description }}
                          </div>
                        </b-td>
                        <b-td colspan="2" class="text-right">
                          {{ item.quantity }}
                        </b-td>
                        <b-td colspan="2" class="text-right">
                          {{ numberFormatter(item.unit_price) }}
                        </b-td>
                        <b-td colspan="2" class="text-right">
                          {{ numberFormatter(item.total_price) }}
                        </b-td>
                      </b-tr>
                    </b-tbody>
                  </b-table-simple>
                </b-col>
              </b-row>
              <b-row class="mt-3 mb-3">
                <b-col cols="12">
                  <b-table-simple
                    fixed
                    table-class="print-friendly"
                    v-show="softCostsLines.length > 0"
                  >
                    <b-thead>
                      <b-tr>
                        <b-th class="table-background-color" colspan="6">
                          {{ $t("changeOrder.softCostsTableTitle", "en") }}
                        </b-th>
                        <b-th class="table-background-color text-right" colspan="2">
                          {{ $t("changeOrder.quantity", "en") }}
                        </b-th>
                        <b-th class="table-background-color text-right" colspan="2">
                          {{ $t("changeOrder.unitPrice", "en") }}
                        </b-th>
                        <b-th class="table-background-color text-right" colspan="2">
                          {{ $t("changeOrder.subtotal", "en") }}
                        </b-th>
                      </b-tr>
                    </b-thead>
                    <b-tbody style="border-bottom: 1px solid #d8d6de !important">
                      <b-tr
                        class="border-group-details"
                        v-for="(softCostLine, index) in softCostsLines"
                        :key="index"
                      >
                        <b-td colspan="6">
                          <div
                            style="
                              font-style: normal;
                              font-weight: 600;
                              font-size: 14px;
                              line-height: 21px;
                              display: flex;
                              align-items: center;
                              color: #6e6b7b;
                            "
                          >
                            {{ softCostLine.name }}
                          </div>
                          <div
                            v-if="softCostLine.client_description"
                            class="op-sc-details child-description"
                            style="
                              font-style: normal;
                              font-weight: 400;
                              font-size: 14px;
                              line-height: 21px;
                              display: flex;
                              align-softcostlines: center;
                              color: #6e6b7b;
                              white-space: pre-line;
                            "
                          >
                            {{ softCostLine.client_description }}
                          </div>
                        </b-td>
                        <b-td colspan="2" class="text-right">
                          {{ softCostLine.quantity }}
                        </b-td>
                        <b-td colspan="2" class="text-right">
                          {{ numberFormatter(softCostLine.unit_price) }}
                        </b-td>
                        <b-td colspan="2" class="text-right">
                          {{ numberFormatter(softCostLine.total_price) }}
                        </b-td>
                      </b-tr>
                    </b-tbody>
                  </b-table-simple>
                </b-col>
              </b-row>
              <div class="pagebreak">
                <b-table-simple fixed table-class="text-nowrap print-friendly">
                  <b-tfoot>
                    <b-tr class="border-footer">
                      <b-td
                        class="child-label border-child subtotalsChangeOrders text-right"
                        colspan="10"
                        style="text-align: end"
                      >
                        {{ $t("changeOrder.subtotal", "en") }}
                      </b-td>
                      <b-td
                        class="child-label border-child subtotalsChangeOrders text-right"
                        colspan="2"
                        style="
                          font-weight: 700;
                          font-size: 14px;
                          line-height: 21px;
                          color: #6e6b7b;
                        "
                      >
                        {{ numberFormatter(changeOrder.subtotal) }}
                      </b-td>
                    </b-tr>
                    <b-tr v-if="changeOrder.admin_fee_enabled">
                      <b-td
                        class="child-label border-child subtotalsChangeOrders"
                        colspan="10"
                        style="text-align: end"
                      >
                        {{ $t("changeOrder.adminFee", "en") }}
                        {{ changeOrder.admin_fee }}%
                      </b-td>
                      <b-td
                        class="child-label border-child subtotalsChangeOrders text-right"
                        colspan="2"
                        style="
                          font-weight: 700;
                          font-size: 14px;
                          line-height: 21px;
                          color: #6e6b7b;
                        "
                      >
                        {{ numberFormatter(changeOrder.admin_fee_subtotal) }}
                      </b-td>
                    </b-tr>
                    <b-tr>
                      <b-td
                        class="child-label border-child"
                        colspan="10"
                        style="
                          text-align: end;
                          font-style: normal;
                          font-weight: 600;
                          font-size: 28px;
                          line-height: 28px;
                          text-align: right;
                          color: #636363;
                        "
                      >
                        {{ $t("changeOrder.total", "en") }}
                      </b-td>
                      <b-td
                        class="child-label border-child text-right"
                        colspan="2"
                        style="
                          font-style: normal;
                          font-weight: 700;
                          font-size: 28px;
                          line-height: 34px;
                          text-align: center;
                        "
                      >
                        {{
                          changeOrder.admin_fee_enabled
                            ? numberFormatter(
                                changeOrder.admin_fee_subtotal + changeOrder.subtotal
                              )
                            : numberFormatter(changeOrder.subtotal)
                        }}
                      </b-td>
                    </b-tr>
                  </b-tfoot>
                </b-table-simple>

                <hr />
                <p class="notesLabel">
                  {{ $t("changeOrder.notesLabel", "en") }}
                </p>
                <p class="notes" v-html="$t('changeOrder.notes', 'en')" />
              </div>
            </b-card-body>
            <b-card-body class="invoice-padding pt-0 my-3">
              <span class="confidential-line float-right footer-quote">{{
                $t("changeOrder.confidential", "en")
              }}</span>
            </b-card-body>

            <hr class="bg-primary p-0 m-0 hr_bottom" style="height: 8px" />
          </b-card>
        </b-col>

        <!-- Right Col: Card -->
        <b-col class="invoice-actions" md="4" xl="3">
          <!-- <comment-section
            :comments="comments"
            :demolition-image="changeOrder.demolition_image"
            :quote-id="quoteId"
            /> -->
        </b-col>
      </b-row>
    </template>
    <b-modal
      id="approveChangeOrder"
      ref="approveChangeOrder"
      title="Mark Quote as Client Approved"
      :centered="true"
      body-class="modal-padding"
      :no-close-on-backdrop="true"
    >
      Marking a quote as client approved will apply it to all active project data. Only
      approve a quote if the client has signed off on the whole change order package.
      <validation-observer ref="completeChangeOrder" tag="form">
        <form ref="completeQuoteForm">
          <validation-provider
            #default="{ errors }"
            name="Change Order Package"
            rules="required"
          >
            <b-form-group class="pt-1">
              <b-form-checkbox
                v-model="packageApproved"
                :value="true"
                :unchecked-value="false"
                plain
                ><p
                  style="
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 21px;
                    color: #6e6b7b;
                  "
                >
                  Change Order Package Approved
                </p></b-form-checkbox
              >
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </form>
      </validation-observer>
      <template #modal-footer="{ cancel }">
        <b-button variant="outline-secondary" @click="cancel()" :disabled="modalIsBusy">
          Cancel
        </b-button>
        <!-- Button with custom close trigger value -->
        <b-button
          variant="primary"
          @click="approveChangeOrder()"
          :disabled="!packageApproved || modalIsBusy"
        >
          Approve Quote
        </b-button>
      </template>
    </b-modal>
    <!-- start sent modal -->
    <b-modal
      id="markAsSent"
      ref="markAsSent"
      title="Mark Change Order as Sent to Client"
      body-class="modal-padding"
      :centered="true"
      :no-close-on-backdrop="true"
    >
      Marking this change order as sent will lock the change order. It can still be
      viewed, approved, and archived but can no longer be edited.

      <template #modal-footer="{ cancel }">
        <b-button variant="outline-secondary" @click="cancel()" :disabled="modalIsBusy">
          Cancel
        </b-button>
        <!-- Button with custom close trigger value -->
        <b-button variant="primary" @click="markAsSent()" :disabled="modalIsBusy">
          Mark as Sent
        </b-button>
      </template>
    </b-modal>
    <!-- end sent modal -->
    <b-modal
      id="archiveQuote"
      ref="archiveQuote"
      title="Archive Change Order"
      @ok="archiveChangeOrder"
      ok-title="Archive Change Order"
      cancel-title="Go Back"
      cancel-variant="outline-secondary"
      :centered="true"
      body-class="modal-padding"
      :no-close-on-backdrop="true"
      :ok-disabled="modalIsBusy"
      :cancel-disabled="modalIsBusy"
    >
      An archived change order will still be available to view but can no longer be
      edited.
    </b-modal>
  </section>
</template>

<script>
import {
  UilBill,
  UilBox,
  UilCheckCircle,
  UilEditAlt,
  UilExclamationTriangle,
  UilHardHat,
  UilPlus,
  UilRuler,
  UilSuitcase,
  UilTape,
  UilWifi,
  UilChartLine,
  UilImport,
  UilSync,
  UilArchive,
  UilMessage,
  UilCheck,
  UilArrowCircleLeft,
  UilFileMinusAlt,
} from "@iconscout/vue-unicons";
import {
  BButton,
  BCard,
  BCardBody,
  BCol,
  BRow,
  VBToggle,
  BNavItem,
  BBadge,
  BFormCheckbox,
  BDropdown,
  BDropdownItem,
  BFormGroup,
  BFormTextarea,
  BDropdownDivider,
  BTableSimple,
  BTbody,
  BTd,
  BTfoot,
  BTh,
  BThead,
  BTr,
  BDropdownGroup,
  BDropdownHeader,
} from "bootstrap-vue";
import Logo from "@core/layouts/components/Logo.vue";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import CommentSection from "../Comments/Components/Comment.vue";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItemDPMOutput.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ContractViewCard from "./Components/ContractViewCard.vue";
import useAppConfig from "@core/app-config/useAppConfig";
import { computed } from "@vue/composition-api";

export default {
  directives: {
    Ripple,
    "b-toggle": VBToggle,
  },
  components: {
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    Logo,
    CommentSection,
    AppCollapse,
    AppCollapseItem,
    UilRuler,
    UilSuitcase,
    UilBill,
    UilTape,
    UilBox,
    UilWifi,
    UilHardHat,
    UilPlus,
    UilCheckCircle,
    UilEditAlt,
    UilExclamationTriangle,
    BNavItem,
    BBadge,
    BFormCheckbox,
    BDropdown,
    BDropdownItem,
    UilChartLine,
    UilImport,
    UilSync,
    BFormGroup,
    BFormTextarea,
    UilArchive,
    BDropdownDivider,
    BTableSimple,
    BTbody,
    BTd,
    BTfoot,
    BTh,
    BThead,
    BTr,
    ContractViewCard,
    UilMessage,
    UilCheck,
    UilArrowCircleLeft,
    UilFileMinusAlt,
    BDropdownGroup,
    BDropdownHeader,
  },
  data() {
    return {
      opportunityId: this.$route.params.opportunityId,
      changeOrderId: this.$route.params.changeOrderId,
      changeOrder: {},
      changeOrderLines: {},
      quote: {
        opportunity: {},
        comments: {},
      },
      comments: {},
      options: {},
      siteConditions: {},
      show: true,
      newComment: "",
      packageApproved: false,
      modalIsBusy: false,
      isAdmin: false,
    };
  },
  beforeMount() {
    this.getChangeOrder();
    this.isAdmin = localStorage.getItem("isAdmin");
  },
  computed: {
    constructionLines() {
      const results = this.changeOrderLines.filter(
        (x) => x.budget_group === "Construction"
      );
      return results;
    },
    ffeLines() {
      const results = this.changeOrderLines.filter((x) => x.budget_group === "FF&E");
      return results;
    },
    avitLines() {
      const results = this.changeOrderLines.filter((x) => x.budget_group === "AV/IT");
      return results;
    },
    changeOrderItems() {
      const results = this.changeOrderLines.filter((x) => x.budget_group != "Soft Costs");
      return results;
    },
    softCostsLines() {
      const results = this.changeOrderLines.filter(
        (x) => x.budget_group === "Soft Costs"
      );
      return results;
    },
  },

  setup() {
    const { skin } = useAppConfig();

    const isDark = computed(() => skin.value === "dark");

    return { skin, isDark };
  },
  methods: {
    getChangeOrder() {
      this.$http
        .get(`/opportunities/${this.opportunityId}/change-orders/${this.changeOrderId}`)
        .then((response) => {
          this.changeOrder = response.data.data;
          this.changeOrderLines = this.changeOrder.change_order_lines;
          this.show = false;
          if (this.changeOrder.change_order_number) {
            document.title = `[${this.changeOrder.change_order_number}] View Change Order`;
          }
          this.updateBreadcrumb();
        })
        .catch((error) => {
          this.showToast("danger", error);
        });
    },
    updateBreadcrumb() {
      this.$route.meta.breadcrumb[0].text = `${this.changeOrder.opportunity.address}, ${this.changeOrder.opportunity.geography}`;
      if (this.changeOrder.opportunity.marked_as_active) {
        this.$route.meta.breadcrumb[0].to = `/active/${this.opportunityId}`;
      } else {
        this.$route.meta.breadcrumb[0].to = `/opportunities/${this.opportunityId}`;
      }
      this.$route.meta.breadcrumb[0].active = false;
      this.$route.meta.breadcrumb[2].text = `Change Order ${this.changeOrder.change_order_number}`;
      this.$root.$emit("rerender-breadcrumb");
    },
    badgeClass(status) {
      if (status == "Draft") {
        return "draft";
      } else if (status == "Approved") {
        return "approved";
      } else if (status == "Archived") {
        return "archived";
      } else if (status == "Quote In Progress") {
        return "quote-in-progress";
      } else if (status == "Sent to Client") {
        return "sent-to-client";
      }
    },
    printInvoice() {
      let isDarkMode = this.isDark ? "light" : "dark";
      console.log(isDarkMode);
      // this.skin = this.isDark ? "light" : "dark";
      if (isDarkMode != "dark") {
        this.skin = "light";
      }

      setTimeout(() => {
        document.title =
          "Clearspace - " +
          this.changeOrder.id +
          " - " +
          this.changeOrder.opportunity.account_name +
          " - " +
          this.changeOrder.opportunity.address;
        window.print();
        if (isDarkMode != "dark") {
          this.skin = "dark";
        }
      }, 100);
    },
    archiveChangeOrder() {
      this.modalIsBusy = true;
      this.$http
        .get(
          `/opportunities/${this.opportunityId}/change-orders/${this.changeOrderId}/archive`
        )
        .then((response) => {
          this.modalIsBusy = false;
          this.changeOrder.status = "Archived";
          this.showToast(
            "success",
            "The Change Order will still be available to view but can no longer be edited.",
            "Change Order Archived"
          );
        })
        .catch((error) => {
          this.modalIsBusy = false;
          this.showToast(
            "danger",
            "Contact Digital for support.",
            "Something went wrong"
          );
        });
    },
    revertToQuoteStatus() {
      this.modalIsBusy = true;
      this.$http
        .get(
          `/opportunities/${this.opportunityId}/change-orders/${this.changeOrderId}/revert-to-quote-in-progress`
        )
        .then((response) => {
          this.modalIsBusy = false;
          this.changeOrder.status = "Quote In Progress";
          this.showToast(
            "success",
            "The Change Order has been reverted to quote in progress.",
            "Change Order Status Updated"
          );
        })
        .catch((error) => {
          this.modalIsBusy = false;
          this.showToast(
            "danger",
            "Contact Digital for support.",
            "Something went wrong"
          );
        });
    },
    approveChangeOrder() {
      return new Promise((resolve, reject) => {
        this.$refs.completeChangeOrder.validate().then((success) => {
          if (success) {
            this.modalIsBusy = true;
            this.$http
              .get(
                `/opportunities/${this.opportunityId}/change-orders/${this.changeOrderId}/approve`
              )
              .then((response) => {
                this.modalIsBusy = false;
                this.changeOrder.status = "Approved";
                this.showToast(
                  "success",
                  "The Change Order will still be available to view but can no longer be edited.",
                  "Change Order Archived"
                );
                this.$nextTick(() => {
                  //this.$bvModal.hide('attachTestFit')
                  this.$refs["approveChangeOrder"].hide();
                });
                resolve(true);
              })
              .catch((error) => {
                this.modalIsBusy = false;
                this.showToast(
                  "danger",
                  "Contact Digital for support.",
                  "Something went wrong"
                );
                reject();
              });
          } else {
            reject();
          }
        });
      });
    },
    markAsSent() {
      this.modalIsBusy = true;
      this.$http
        .get(
          `/opportunities/${this.opportunityId}/change-orders/${this.changeOrderId}/mark-sent-to-client`
        )
        .then((response) => {
          this.modalIsBusy = false;
          this.changeOrder.status = "Sent to Client";
          this.showToast(
            "success",
            "This change order can no longer be edited.",
            "Change Order Sent"
          );
          this.$nextTick(() => {
            //this.$bvModal.hide('attachTestFit')
            this.$refs["markAsSent"].hide();
          });
        })
        .catch((error) => {
          this.modalIsBusy = false;
          this.showToast(
            "danger",
            "Contact Digital for support.",
            "Something went wrong"
          );
        });
    },

    numberFormatter(number) {
      return (
        (number < 0 ? "-" : "") +
        "$" +
        Number(Math.abs(number.toFixed(2))).toLocaleString("en-CA", {
          minimumFractionDigits: 2,
        })
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
</style>
<style lang="scss">
@media print {
  html {
    overflow: visible !important;
  }
  @page {
    margin: 10mm 0 0mm 0;
  }
  // Global Styles
  body {
    background-color: transparent !important;
    height: 99% !important;
    margin: 0;
    padding: 0;
  }
  .hr_bottom {
    display: none;
  }
  .invoice_header {
    margin-left: -30px !important;
    margin-top: -30px !important;
    padding: 0 15px !important;
    width: 1148px !important;
  }
  .invoice_header_second {
    margin-left: -30px !important;
    padding: 0 30px !important;
    width: 1148px !important;
  }
  .footer-quote {
    position: fixed;
    bottom: 40px;
    right: 50px;
  }

  .pagebreak {
    page-break-inside: avoid !important;
  }
  .nextPage {
    page-break-before: always;
  }
  .printable {
    display: block !important;
  }
  .notprintable {
    display: none;
  }

  .breadcrumbs-top {
    display: none;
  }

  nav.header-navbar {
    display: none;
  }
  .nav.nav-tabs {
    display: none;
  }

  .main-menu {
    display: none;
  }

  .header-navbar-shadow {
    display: none !important;
  }

  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }

  footer.footer {
    display: none;
  }

  .card {
    background-color: transparent;
    box-shadow: none;
  }

  .customizer-toggle {
    display: none !important;
  }

  [dir] .table:not(.table-dark):not(.table-light) thead:not(.thead-dark) th,
  [dir] .table:not(.table-dark):not(.table-light) tfoot:not(.thead-dark) th {
    background-color: rgba(92, 177, 231, 0.12) !important;
  }

  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
            > .col-12 {
              max-width: 100% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .invoice-actions {
      display: none;
    }
  }

  #lottie {
    display: none;
  }

  .caption-table {
    /* Heading/H4 */

    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 18px;
    padding-left: 40px;
    color: #5e5873;
  }

  .row-label {
    /* Body/Paragraph Semi Bold */

    font-style: normal;
    font-weight: 600;
    font-size: 14px;

    /* identical to box height, or 150% */

    align-items: center;

    /* Typography/Body */

    color: #6e6b7b;
  }

  .border-group {
    border-bottom: 2px solid #d8d6de;
  }

  .border-group-details {
    border-bottom: 1px solid rgba(31, 41, 47, 0.05) !important;
  }

  .border-child {
    border-bottom: 2px solid white;
  }

  .child-label {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
  }

  .border-footer {
    border-top: 2px solid rgba(50, 61, 76, 1) ;
  }

  .border-footer-light {
    border-top: 2px solid #d8d6de;
  }

  .invoice-numbercs {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 18px;
  }

  .header-label {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
  }

  .header-label-grey {
    /* Table/Table Header */

    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 12px;
    /* identical to box height */

    text-align: right;
    letter-spacing: 1px;
    text-transform: uppercase;

    /* Typography/Muted & Placeholder */

    color: #b9b9c3;
  }

  .header-subtotal {
    font-style: normal;
    font-weight: 600;
    font-size: 11.9px;
    line-height: 12px;
    /* identical to box height, or 100% */

    text-align: right;
    letter-spacing: 0.14px;

    /* Typography/Body */

    color: #6e6b7b;
  }

  .project-sqft {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    /* identical to box height, or 150% */

    /* Typography/Heading & Display */

    color: #5e5873;
  }

  .project-sqft-total {
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 34px;
    text-align: end;

    /* Theme Colour / Primary */

    color: rgba(59, 171, 255, 1);
  }

  .width-100p {
    width: 100%;
  }
  .credit {
    color: rgba(59, 171, 255, 1);
  }
  hr {
    display: block;
    height: 1px;
    background: transparent;
    width: 100%;
  }

  th {
    text-align: left !important;
  }
}

th {
  text-align: left !important;
  padding: 18px !important;
}

.comment-label {
  font-weight: 600;
  font-size: 12px;
  line-height: 23px;
  letter-spacing: 0.6px;
  text-transform: uppercase;
}

.caption-table {
  /* Heading/H4 */

  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 18px;
  padding-left: 40px;
  color: #5e5873;
}

.row-label {
  /* Body/Paragraph Semi Bold */

  font-style: normal;
  font-weight: 600;
  font-size: 14px;

  /* identical to box height, or 150% */

  align-items: center;

  /* Typography/Body */

  color: #6e6b7b;
}

.border-group {
  border-bottom: 2px solid #d8d6de;
}

.border-group-details {
  border-bottom: 1px solid rgba(31, 41, 47, 0.05);
}

.border-child {
  border-bottom: 2px solid white;
}

.child-label {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
}

.border-footer {
  border-top: 2px solid  rgba(50, 61, 76, 1);
}

.invoice-numbercs {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 18px;
}

.header-label {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
}

.header-label-grey {
  /* Table/Table Header */

  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  /* identical to box height */

  text-align: right;
  letter-spacing: 1px;
  text-transform: uppercase;

  /* Typography/Muted & Placeholder */

  color: #b9b9c3;
}

.header-subtotal {
  font-style: normal;
  font-weight: 600;
  font-size: 11.9px;
  line-height: 12px;
  /* identical to box height, or 100% */

  text-align: right;
  letter-spacing: 0.14px;

  /* Typography/Body */

  color: #6e6b7b;
}

.project-sqft {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height, or 150% */

  /* Typography/Heading & Display */

  color: #5e5873;
}

.project-sqft-total {
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 34px;
  text-align: end;

  /* Theme Colour / Primary */

  color: rgba(59, 171, 255, 1);
}

.width-100p {
  width: 100%;
}

.child-line {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #b9b9c3;
}

.project-total-label {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 18px;
  color: #5e5873;
}

.project-total-number {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 18px;
  color: rgba(59, 171, 255, 1);
}

.op-sc-details {
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #b9b9c3;
}

.disclaimer {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #b9b9c3;
}
.confidential-line {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #b9b9c3;
  text-transform: uppercase;
}

.credit {
  color: rgba(59, 171, 255, 1);
}

.section-description {
  color: #6e6b7b;
  font-size: 12px;
  font-weight: normal;
}

.base-paragraph {
  font-size: 12px;
}
.dpm-toggles {
  padding-top: 21px;
}

.notesLabel {
  font-weight: 600;
  font-size: 12px;
  line-height: 23px;
  margin-bottom: 0px;
  letter-spacing: 0.6px;
  text-transform: uppercase;
  color: #6e6b7b;
}

.notes {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #6e6b7b;
}

.headerLabel {
  font-weight: 700 !important;
  font-size: 20px !important;
  line-height: 24px !important;
  color: #636363 !important;
}

.subtotalsChangeOrders {
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  color: #636363;
}
//  new theming colors
[dir] .table:not(.table-dark):not(.table-light) thead:not(.thead-dark) th,
[dir] .table:not(.table-dark):not(.table-light) tfoot:not(.thead-dark) th {
  background-color: rgba(50, 61, 76, 1) !important;
}

.table-background-color {
  color: azure !important;
}

.table_sub_header {
  background-color: rgb(235, 246, 252) !important;
}
.table_border_base {
  border-bottom: #6e6b7b solid 1px !important;
}
</style>
